import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";

class Notf extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Not fount" />
        <section
          data-side="404"
          className="contentbox wrap --fullw --wideblue --novideo"
        >
          <div className="textcontent">
            <h2>
              404 <span>Page not found</span>
            </h2>
          </div>
        </section>
      </Layout>
    );
  }
}
export default Notf;
